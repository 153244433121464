<template>
  <div class="mt-4  px-5">
    <h2>Meine Ausbildung</h2>
    <v-data-table
        :headers="headers"
        :items="comp_myEdu"
        sort-by="kurzbez"
        class="elevation-1 mt-4"
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-spacer></v-spacer>
          <suchen_zuordnen
              titel="Neue Ausbildung"
              btn_title="Neue Ausbildung"
              btn_icon="mdi-school"
              :content="comp_allEdu"
              :headers="headers_suche"
              @GET_SELECTION="save_education"/>
          <v-btn icon
                 @click="get_education">
            <v-icon>mdi-reload</v-icon>
          </v-btn>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Willst du diesen Eintrag wirklich löschen?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Abbrechen</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">Ja</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-spacer></v-spacer>
        <v-btn class="error my-1" text @click="deleteItem(item)">
          <v-icon class="mr-2">
            mdi-delete
          </v-icon>
          Löschen
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-btn
            color="primary"
            @click="initialize"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import suchen_zuordnen from "@/views/component/suchen_zuordnen.vue";

export default {
  name: "education",
  components: {suchen_zuordnen},

  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        align: 'Bezeichnung',
        sortable: false,
      },
      {text: 'Kurz', value: 'kurzbez'},
      {text: 'Bezeichnung', value: 'Langbez'},
      {text: 'Actions', value: 'actions', sortable: false, align: 'center',width: '250px'},
    ],

    headers_suche: [
      {text: 'Kurz', value: 'kurzbez'},
      {text: 'Bezeichnung', value: 'Langbez'}
    ],

    editedItem: [],
    delItem: [],
  }),

  computed: {
    comp_allEdu() {
      return this.$store.state.UserApp.Education.allEdu;
    },
    comp_myEdu() {
      return this.$store.state.UserApp.Education.myEdu;
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  mounted() {
  },

  methods: {

    get_education(){
      this.$store.dispatch('UserApp/getEduData');
    },

    save_education(item) {
      var Data = {
        inputAusbildungID: item.id,
      };
      this.$store.dispatch('UserApp/setEducation', Data)
          .then(this.dialog = false);
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    deleteItem(item) {
      this.delItem = item;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      var Data = {
        inputDelAusbildungID: this.delItem.id,
      };
      this.$store.dispatch('UserApp/delEducation', Data)
          .then(this.dialogDelete = false);
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    initialize() {
    },


  },
}
</script>

<style scoped>

</style>
